import type { ReactNode } from "react";

import { SectionHeading } from "../headings/SectionHeading.js";

export interface FeedbackProps {
  actions?: ReactNode;
  children?: ReactNode;
  image?: ReactNode;
  title?: string;
}

export function Feedback({ actions, children, image, title }: FeedbackProps) {
  const isPrimitive = typeof children === "string" || typeof children === "number";

  return (
    <div className="flex flex-col items-center justify-center py-4 sm:py-8">
      <div className="max-w-sm space-y-8">
        {image}
        <div className="flex flex-col items-center space-y-4 text-center">
          {title === undefined ? null : <SectionHeading.Title>{title}</SectionHeading.Title>}
          {children === undefined ? null : isPrimitive ? <p>{children}</p> : children}
        </div>
        {actions === undefined ? null : <div className="flex items-center justify-center space-x-3">{actions}</div>}
      </div>
    </div>
  );
}
